<template>
  <el-dialog
    v-dialogDrag
    title="确认下单？"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="360px"
  >
    <div class="orderConfirm">
      <div class="confirmList">
        <p class="listTitle">请选择下单方式：</p>
        <el-radio-group v-model="method">
          <el-radio :label="1">现货下单</el-radio>
          <el-radio :label="2">履约订金下单</el-radio>
        </el-radio-group>
      </div>
      <div class="confirmList">
        <p class="listTitle">请选择持仓：</p>
        <el-select class="width-100" v-model="holdId">
          <el-option
            v-for="items in categoryOptions"
            :key="items.holdId"
            :label="
              items.holdId +
                '-' +
                items.className +
                (items.manufacturer ? '-' + items.manufacturer : '') +
                (items.placeOrigin ? '-' + items.placeOrigin : '') +
                (items.brand ? '-' + items.brand : '') +
                (items.holdWeight
                  ? '-' +
                    (Number(items.holdWeight) -
                      Number(items.frozenHoldWeight)) +
                    items.unit
                  : '')
            "
            :value="items.holdId"
          ></el-option>
        </el-select>
      </div>
      <div>
        <h5 style="line-height:50px">摘单重量：</h5>
        <el-input
          v-model="pickResourcesWeight"
          :disabled="isTradeWay"
          @change="inputBlur"
          :placeholder="weightPlaceHolder"
        ></el-input>
      </div>
      <p v-if="method == 2" style="margin-top:20px">
        冻结履约订金：{{ marginPrice > 0 ? marginPrice : "---" }}元
      </p>
      <!-- <p v-if="method==1" style="margin-top:20px">全款：{{marginPrice > 0 ? marginPrice : '---'}}元</p> -->
      <!-- <div v-if="method === 2" class="confirmList">
        <p class="listTitle">请选择仓储：</p>
        <el-select class="width-100" v-model="warehousId">
          <el-option
            v-for="item in warehousIdOptions"
            :key="item.warehousId"
            :label="item.commodity + ' ' + item.warehousCode"
            :value="item.warehousId"
          ></el-option>
        </el-select>
      </div>-->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="isLoadingOrder" @click="goOrder()"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      rowData: null,
      id: null,
      dialogVisible: false,
      isLoadingOrder: false,
      method: 1,
      holdId: null,
      warehousId: null,
      warehousIdOptions: [],
      right: {
        holdSell: false,
        moneySell: false
      },
      pickResourcesWeight: null,
      categoryOptions: [],
      weightPlaceHolder: "",
      isTradeWay: false,
      price: null,
      unsettledWeight: null,
      // 商品id
      commodityId: null
    };
  },
  watch: {
    method() {
      this.categoryOptions = [];
      this.holdId = null;
      protocolFwd.param_queryHold.param.commodityId = this.commodityId;
      this.getHoldOptions();
    }
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    marginPrice() {
      if (this.method == 2) {
        return Number(
          Number(this.pickResourcesWeight) *
            Number(this.price) *
            Number(this.getMarginRateGetter.marginRate)
        ).toFixed(2);
      } else if (this.method == 1) {
        return Number(
          Number(this.pickResourcesWeight) * Number(this.price)
        ).toFixed(2);
      } else {
        return 0;
      }
    }
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.holdSell = this.isHasRight("tc-trade-pickStockOrder");
          this.right.moneySell = this.isHasRight(
            "tc-trade-pickSellPresaleResource"
          );
        }
      });
    },
    showDialog(row) {
      console.log(row, 8971);
      this.rowData = row;
      if (row.tradeWay == "P") {
        if (
          row.minPickWeight &&
          Number(row.unsettledWeight) >= Number(row.minPickWeight)
        ) {
          this.weightPlaceHolder =
            "可摘单重量：" +
            row.unsettledWeight +
            row.unit +
            ";最小摘单重量" +
            row.minPickWeight +
            row.unit;
        } else {
          this.weightPlaceHolder =
            "可摘单重量：" + row.unsettledWeight + row.unit;
        }
        this.isTradeWay = false;
      } else {
        this.weightPlaceHolder = "";
        this.pickResourcesWeight = row.unsettledWeight;
        this.isTradeWay = true;
      }
      this.dialogVisible = true;
      this.unsettledWeight = row.unsettledWeight;
      this.id = row.hangResourcesId;
      this.price = row.price;
      this.commodityId = row.commodityId;
      this.getHoldOptions();
      this.getRights();
    },
    inputBlur(value) {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return this.$EL_MESSAGE("请输入摘单重量");
      } else if (!reg.test(value) || Number(value) <= 0) {
        return this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
      } else if (Number(value) > Number(this.unsettledWeight)) {
        return this.$EL_MESSAGE("超过可摘单重量");
      }
    },
    getHoldOptions() {
      let commodityList = [];
      if (this.method === 1) {
        // 现货下单
        protocolFwd.param_queryHold.param.holdType = ["X", "B"];
      } else {
        protocolFwd.param_queryHold.param.holdType = ["Y"];
      }
      protocolFwd.param_queryHold.param.commodityId = this.commodityId;
      protocolFwd.param_queryHold.param.holdId = null;
      protocolFwd.param_queryHold.param.page = 0;
      protocolFwd.param_queryHold.param.size = 10000;
      protocolFwd.param_queryHold.param.sellStatus = "T";
      protocolFwd.param_queryHold.param.auditHoldStatus = "T";
      protocolFwd.param_queryHold.param.specificationsName = this.rowData.specificationsName;
      protocolFwd.param_queryHold.param.storageId = this.rowData.storageId;
      protocolFwd.param_queryHold.param.unitId = this.rowData.unitId;
      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.categoryOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getWarehousIdOptions(commodity) {
      let commodityList = [];
      protocolFwd.param_querySpotWarehouse.param.fStatus = [0];
      http.postFront(protocolFwd.param_querySpotWarehouse).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].commodity == commodity) {
              commodityList.push(value[i]);
            }
          }
          this.warehousIdOptions = commodityList;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goOrder() {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (!this.pickResourcesWeight) {
        this.$EL_MESSAGE("请输入摘单重量");
        return;
      } else if (
        !reg.test(this.pickResourcesWeight) ||
        Number(this.pickResourcesWeight) <= 0
      ) {
        return this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
      }
      if (this.method === 1) {
        // 现货下单
        if (this.right.holdSell) {
          this.isLoadingOrder = true;
          protocolFwd.param_pickStockOrder.param.hangResourcesId = this.id;
          protocolFwd.param_pickStockOrder.param.pickResourcesWeight = this.pickResourcesWeight;
          protocolFwd.param_pickStockOrder.param.holdId = this.holdId;
          // protocolFwd.param_pickStockOrder.param.warehousId = this.warehousId;
          http
            .postFront(protocolFwd.param_pickStockOrder)
            .then(response => {
              const { code, message, value } = response.data;
              this.pickResourcesWeight = null;
              if (code == 0) {
                this.$confirm("查看订单", "下单成功", {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "去看看",
                  cancelButtonText: "取消"
                })
                  .then(() => {
                    this.$router.push({
                      name: "sellerSalesSpotOrder"
                    });
                  })
                  .catch(action => {});
                this.isLoadingOrder = false;
              } else {
                this.$EL_MESSAGE(message);
                this.isLoadingOrder = false;
              }
              this.dialogVisible = false;
            })
            .catch(() => {
              this.isLoadingOrder = false;
              this.pickResourcesWeight = null;
            });
        } else {
          this.$EL_MESSAGE("您无操作权限");
          this.pickResourcesWeight = null;
        }
      } else if (this.method === 2) {
        if (this.right.moneySell) {
          this.isLoadingOrder = true;
          protocolFwd.param_pickSellPresaleResource.param.hangResourcesId = this.id;
          protocolFwd.param_pickSellPresaleResource.param.pickResourcesWeight = this.pickResourcesWeight;
          protocolFwd.param_pickSellPresaleResource.param.holdId = this.holdId;

          http
            .postFront(protocolFwd.param_pickSellPresaleResource)
            .then(response => {
              const { code, message, value } = response.data;
              this.pickResourcesWeight = null;
              if (code == 0) {
                this.$confirm("查看订单", "下单成功", {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "去看看",
                  cancelButtonText: "取消"
                })
                  .then(() => {
                    this.$router.push({
                      name: "sellerSalesSpotOrder"
                    });
                  })
                  .catch(action => {});
                this.isLoadingOrder = false;
              } else {
                this.$EL_MESSAGE(message);
                this.isLoadingOrder = false;
              }
              this.dialogVisible = false;
            })
            .catch(() => {
              this.isLoadingOrder = false;
              this.pickResourcesWeight = null;
            });
        } else {
          this.$EL_MESSAGE("您无操作权限");
          this.pickResourcesWeight = null;
        }
      }
    },
    handleClose() {
      this.pickResourcesWeight = null;
      this.holdId = null;
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.orderConfirm .confirmList {
  margin-bottom: 15px;
}
.orderConfirm .confirmList .listTitle {
  margin-bottom: 10px;
}
</style>
