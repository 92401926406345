import { render, staticRenderFns } from "./preGoodsList.vue?vue&type=template&id=f8ebfd20&scoped=true"
import script from "./preGoodsList.vue?vue&type=script&lang=js"
export * from "./preGoodsList.vue?vue&type=script&lang=js"
import style0 from "./preGoodsList.vue?vue&type=style&index=0&id=f8ebfd20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ebfd20",
  null
  
)

export default component.exports