var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"确认下单？","close-on-click-modal":false,"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"width":"360px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"orderConfirm"},[_c('div',{staticClass:"confirmList"},[_c('p',{staticClass:"listTitle"},[_vm._v("请选择下单方式：")]),_c('el-radio-group',{model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("现货下单")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("履约订金下单")])],1)],1),_c('div',{staticClass:"confirmList"},[_c('p',{staticClass:"listTitle"},[_vm._v("请选择持仓：")]),_c('el-select',{staticClass:"width-100",model:{value:(_vm.holdId),callback:function ($$v) {_vm.holdId=$$v},expression:"holdId"}},_vm._l((_vm.categoryOptions),function(items){return _c('el-option',{key:items.holdId,attrs:{"label":items.holdId +
              '-' +
              items.className +
              (items.manufacturer ? '-' + items.manufacturer : '') +
              (items.placeOrigin ? '-' + items.placeOrigin : '') +
              (items.brand ? '-' + items.brand : '') +
              (items.holdWeight
                ? '-' +
                  (Number(items.holdWeight) -
                    Number(items.frozenHoldWeight)) +
                  items.unit
                : ''),"value":items.holdId}})}),1)],1),_c('div',[_c('h5',{staticStyle:{"line-height":"50px"}},[_vm._v("摘单重量：")]),_c('el-input',{attrs:{"disabled":_vm.isTradeWay,"placeholder":_vm.weightPlaceHolder},on:{"change":_vm.inputBlur},model:{value:(_vm.pickResourcesWeight),callback:function ($$v) {_vm.pickResourcesWeight=$$v},expression:"pickResourcesWeight"}})],1),(_vm.method == 2)?_c('p',{staticStyle:{"margin-top":"20px"}},[_vm._v(" 冻结履约订金："+_vm._s(_vm.marginPrice > 0 ? _vm.marginPrice : "---")+"元 ")]):_vm._e()]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.isLoadingOrder},on:{"click":function($event){return _vm.goOrder()}}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }