<template>
  <div class="shopManage subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>店铺管理</p>
      </div>
    </div>
    <div class="subPageContainer">
      <div class="noCertShop" v-if="isAlreadyPass === false">
        <p>您还未通过企业认证，请前往企业认证</p>
        <el-button type="danger" class="certButton" @click="goCert()">企业认证</el-button>
      </div>
      <div class="alreadyCert" v-if="isAlreadyPass === true">
        <div class="shopInfo">
          <div class="logoBox">
            <img class="shopLogo" :src="shopDetails.logo_url" />
          </div>
          <div class="shopDetails">
            <p class="shopItem">
              <span class="shopItemTitle">店铺名称：</span>
              <span class="shopItemText">{{ shopDetails.store_name }}</span>
              <span class="modifyShop" @click="showModifyDialog()">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>修改店铺信息
              </span>
            </p>
            <!-- <p class="shopItem">
              <span class="shopItemTitle">店铺类型：</span>
              <span class="shopItemText">{{ shopDetails.value }}</span>
            </p> -->
            <p class="shopItem">
              <span class="shopItemTitle">店铺介绍：</span>
              <span class="shopItemText">{{ shopDetails.note }}</span>
            </p>
          </div>
        </div>
        <!-- 修改店铺 -->
        <el-dialog v-dialogDrag title="修改店铺" :visible.sync="dialogVisible" width="600px">
          <el-form
            class="modifyShopForm"
            ref="form"
            :rules="rules"
            :model="form"
            label-width="100px"
          >
            <el-form-item label="店铺名称" prop="storeName">
              <el-input v-model="form.storeName"></el-input>
            </el-form-item>
            <!-- <el-form-item label="店铺类型" prop="type">
              <el-select class="width-100" v-model="form.type" placeholder="请选择">
                <el-option
                  v-for="item in shopTypeDatas"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="店铺LOGO" prop="logoUrl">
              <el-upload
                class="avatar-uploader"
                :action="actionUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :data="postData"
              >
                <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="店铺介绍" prop="note">
              <el-input type="textarea" v-model="form.note"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitModifyShop()">提 交</el-button>
          </span>
        </el-dialog>
        <!-- 现货销售 产品预售资源 -->
        <div class="shopResource">
          <el-tabs v-model="activeTabName">
            <!-- <el-tab-pane label="产品预售资源" name="preGoodsList">
              <preGoodsList />
            </el-tab-pane>-->
            <el-tab-pane label="现货销售" name="goodsList">
              <!-- <goodsList /> -->
              <ResourceDocuments :flag="activeTabName" />
            </el-tab-pane>
            <el-tab-pane label="现货采购" name="SpotPurchasing">
              <SpotPurchasing :flag="activeTabName" />
            </el-tab-pane>
            <el-tab-pane label="产品预售" name="SpotSale">
              <SpotSale :flag="activeTabName" />
            </el-tab-pane>
            <el-tab-pane label="竞价交易" name="CompetitivePriceTransaction">
              <CompetitivePriceTransaction :flag="activeTabName" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from "../com/goodsList";
import preGoodsList from "../com/preGoodsList";
import SpotPurchasing from "./components/SpotPurchasing"; // 现货采购
import SpotSale from "./components/SpotSale"; // 产品预售
import CompetitivePriceTransaction from "./components/CompetitivePriceTransaction"; // 竞价交易
import ResourceDocuments from "./components/ResourceDocuments"; // 现货销售
export default {
  data() {
    return {
      isAlreadyPass: null,
      form: {
        storeName: null,
        type: null,
        logoUrl: null,
        note: null,
      },
      rules: {
        storeName: [
          { required: true, message: "请输入店铺名称", trigger: "change" },
        ],
        type: [
          { required: true, message: "请输入店铺名称", trigger: "change" },
        ],
        logoUrl: [
          { required: true, message: "请上传店铺LOGO", trigger: "change" },
        ],
        note: [{ required: true, message: "请输入备注", trigger: "change" }],
      },
      shopTypeDatas: [],
      shopDetails: {},
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null,
      },
      dialogVisible: false,
      activeTabName: "goodsList",
    };
  },
  watch: {},
  components: {
    goodsList,
    preGoodsList,
    SpotPurchasing,
    SpotSale,
    CompetitivePriceTransaction,
    ResourceDocuments,
  },
  mounted() {
    this.getIdentificationStatus();
    // this.getShopType();
    this.getShopDetails();
  },
  methods: {
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_enterpriseCertification)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.status == 2) {
              this.isAlreadyPass = true;
            } else {
              this.isAlreadyPass = false;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    getShopDetails() {
      // protocolFwd.param_queryStore.param.firmId = this.$store.state.common.sessionInfo.firmId;
      protocolFwd.param_queryStore.param.firmId = sessionStorage.getItem('userFirmId')
      http.postFront(protocolFwd.param_queryStore).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.shopDetails = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goCert() {
      this.$router.push("/personalCenter/enterprise");
    },
    showModifyDialog() {
      this.form = {
        storeName: this.shopDetails.store_name,
        type: this.shopDetails.code,
        logoUrl: this.shopDetails.logo_url,
        note: this.shopDetails.note,
      };
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.form.logoUrl = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    getShopType() {
      http.postFront(protocolFwd.param_storeTypeDict).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.shopTypeDatas = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitModifyShop() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          protocolFwd.param_updateStore.param = this.form;
          protocolFwd.param_updateStore.param.firmId = sessionStorage.getItem('userFirmId');
          http.postFront(protocolFwd.param_updateStore).then((response) => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.dialogVisible = false;
              this.getShopDetails();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
/deep/ .el-tabs__header {
  margin-bottom: 0;
}

.subPage {
  padding: 0 15px;
  /* width: 100%; */
  /* background-color: blue; */
}
.subPage .subPageTitle {
  overflow: hidden;
}
.subPage .subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPage .subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.shopManage .noCertShop {
  margin: 100px auto;
  text-align: center;
}
.shopManage .noCertShop p {
  font-size: 16px;
  color: #9999a2;
  text-align: center;
}
.shopManage .noCertShop .certButton {
  margin-top: 20px;
}
.shopManage .submitButton {
  text-align: center;
}
.shopInfo {
  overflow: hidden;
}
.shopInfo .logoBox {
  float: left;
  margin-right: 15px;
  height: 150px;
}
.shopInfo .logoBox .shopLogo {
  max-width: 300px;
  height: 100%;
}
.shopInfo .shopDetails .shopItem {
  line-height: 25px;
  overflow: hidden;
}
.shopInfo .shopDetails .shopItem .shopItemTitle {
  float: left;
  width: 75px;
}
.shopInfo .shopDetails .shopItem .shopItemText {
  color: #9999a2;
}
.shopInfo .shopDetails .modifyShop {
  float: right;
  color: #f56c6c;
  cursor: pointer;
}
.shopResource {
  margin-top: 10px;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
/* 头像 结束 */
</style>

