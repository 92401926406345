<template>
  <div class="preGoodsList">
    <div class="productionList">
      <div class="item" v-for="item in resourceDatas" :key="item.sellOrderId">
        <el-row>
          <el-col :span="10">
            <div class="firstCloumn">
              <p class="title">
                <span class="enterpriseName">{{ item.tradeUnitName }}</span>
                {{ item.name }}
              </p>
              <p class="standard">商品类别：{{ item.typeName }} 产地：{{ item.origin }}</p>
              <p
                class="desc"
              >可供量：{{ item.unsettledQuantity }} 交货期：{{ item.lastPayDay }} 仓库：{{ item.warehouse }}</p>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="secondCloumn">
              <p class="countTitle">剩余可供量</p>
              <p class="count">{{ item.syQuantity }}</p>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="thirdCloumn">
              <p class="priceTitle">成交价</p>
              <p class="price">{{ item.price }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      resourceDatas: []
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter", "userTypeGetter"])
  },
  mounted() {
    this.getPreGoodsList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPreGoodsList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getPreGoodsList();
    },
    getPreGoodsList() {
      protocolFwd.param_queryAdvanceSale.param.commodityName = null;
      protocolFwd.param_queryAdvanceSale.param.origin = null;
      protocolFwd.param_queryAdvanceSale.param.page = this.current - 1;
      protocolFwd.param_queryAdvanceSale.param.size = this.pageSize;
      protocolFwd.param_queryAdvanceSale.param.sort = this.sort;
      protocolFwd.param_queryAdvanceSale.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_queryAdvanceSale).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.resourceDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.preGoodsList .productionList .item {
  padding: 10px 0;
  line-height: 27px;
  border-bottom: 1px dashed #f2f2f2;
}
.preGoodsList .productionList .item .desc {
  color: #9999a2;
}
.preGoodsList .productionList .secondCloumn,
.preGoodsList .productionList .thirdCloumn,
.preGoodsList .productionList .fourthCloumn {
  text-align: center;
}
.preGoodsList .productionList .item .count {
  font-weight: 700;
}
.preGoodsList .productionList .item .price {
  color: $commonThemeColor;
  font-weight: 700;
}
.preGoodsList .productionList .item .endDate {
  color: #9999a2;
  font-size: 14px;
}
.preGoodsList .pagination {
  margin-top: 10px;
}
</style>

